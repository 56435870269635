import { isPlatformBrowser } from '@angular/common';
import { inject, Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AltenarBaseConfig, AltenarConfig, AltenarSportbookConfig } from './altenar';
import { AnalyticEvent } from './analytic-event';
import { Oddin, OddinConfig } from './oddin';
import { Smartico } from './smartico';

/* Create a new injection token for injecting the window into a component. */
export const WINDOW = new InjectionToken<Window | null>('WindowToken', {
  factory: () => {

    const platformId = inject(PLATFORM_ID);

    if (isPlatformBrowser(platformId)) {
      const ua = window.navigator.userAgent;
      window.isMobile = /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|NetFront|Silk-Accelerated|(hpw|web)OS|Fennec|Minimo|Opera M(obi|ini)|Blazer|Dol(fin|phin)|Skyfire|Zune/.test(ua);
      window.isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(ua.toLowerCase());
      window.isDesktop = !window.isMobile && !window.isTablet;
      window.isMac = /(Mac|iPhone|iPod|iPad)/i.test(window.navigator.platform);
      window.isWin = /(Win32)/i.test(window.navigator.platform);
      return window;
    }

    return null;
  }
});

declare global {
  interface Window {
    isMobile: boolean;
    isTablet: boolean;
    isDesktop: boolean;
    isMac: boolean;
    isWin: boolean;
    dataLayer?: Array<AnalyticEvent>;
    _smartico?: Smartico;
    _smartico_language: string;
    _smartico_user_id: string | null;
    oddin: {
      buildBifrost: (config: OddinConfig) => Oddin
    };
    altenarWSDK: {
      set: (config: Partial<AltenarConfig>) => void,
      init: (config: AltenarBaseConfig) => void,
      addSportsBook: (config: AltenarSportbookConfig) => { remove: () => void }
    };
  }
}


@Injectable({
  providedIn: 'root'
})
export class WindowService {

  device$: BehaviorSubject<Window>;

  constructor(@Inject(WINDOW) private window: any) {
    this.device$ = new BehaviorSubject<Window>(this.window);
  }

  // set device(device: 'mobile' | 'tablet' | 'desktop') {
  //   switch (device) {
  //     case 'mobile':
  //       this.window.isMobile = true;
  //       this.window.isTablet = false;
  //       this.window.isDesktop = false;
  //       break;
  //     case 'tablet':
  //       this.window.isMobile = true;
  //       this.window.isTablet = true;
  //       this.window.isDesktop = false;
  //       break;
  //     case 'desktop':
  //       this.window.isMobile = false;
  //       this.window.isTablet = false;
  //       this.window.isDesktop = true;
  //       break;
  //   }
  //
  //   this.device$.next(this.window);
  // }

  // detectDevice(): string {
  //   const width = this.window.innerWidth;
  //
  //   if (width <= 480) {
  //     this.device = 'mobile';
  //     return Device.Mobile;
  //   } else if (width > 480 && width <= 768) {
  //     this.device = 'tablet';
  //     return Device.TabletPortrait;
  //   } else if (width >= 768 && width <= 1024) {
  //     this.device = 'tablet';
  //     return Device.TabletLandscape;
  //   } else if (width >= 992 && width < 1200) {
  //     this.device = 'desktop';
  //     return Device.Desktop;
  //   }
  //
  //   this.device = 'desktop';
  //
  //   return Device.DesktopLg;
  // }
}
